import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import cocacola  from '../assets/images/cocacola.jpg';
import $ from 'jquery';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config';
class Cart extends Component {
componentDidMount(){
$(".order_cancel,.items_box a").click(function(){
	$(".cancel_box,.total_box").fadeIn();
});
$(".close_btn_popup i,.cancel_box").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});

$(".delivery_box li a").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});


$(".delivery_box li a").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeIn();
});

$(".close_btn_icon i,.overlay_poppu").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeOut();
});

// sidebar

$(".dots_icon img").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

$(".slots_items_box .choose_slots").click(function(){
  $(".set_estimated,.set_overlay").fadeIn();
});
$(".set_overlay,.time_picker > i,.set_estimated .gendar input").click(function(){
  $(".set_estimated,.set_overlay").fadeOut();
});


// qtybox

$(document).ready(function() {
	$('.minus').click(function () {
		var $input = $(this).parent().find('input');
		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.change();
		return false;
	});
	$('.plus').click(function () {
		var $input = $(this).parent().find('input');
		$input.val(parseInt($input.val()) + 1);
		$input.change();
		return false;
	});
});
}

  render(){

    return (
<React.Fragment>
          

<body className="yellow_bg border_none login_Bg">

<Header title="My Cart"/>

<section className="login_box__view listing_view" id="height_box">
  <div className="order_details  myroute_search wallet_white" id="wallet_page">
    <div className="info_order">
      <h4>My Cart (4 items)</h4>
      <table className="table">
        <tbody>
        <tr>
          <th>Sub Total</th>
          <td className="order_total fee_green">Rs. 9927</td>
        </tr>
        <tr>
          <th>Delivery Charges</th>
          <td className="order_total fee_green">Free</td>
        </tr>
        <tr>
          <th>Pending Payment</th>
          <td className="order_total fee_green">PKR -299</td>
        </tr>
        <tr>
          <th>Total bill</th>
          <td className="order_total fee_green">Rs. 10226</td>
        </tr>
        <tr>
          <th>Your total savings</th>
          <td className="order_total fee_green total_amount">Rs. 2063</td>
        </tr>
        
    </tbody>
  </table>
    </div>
    <div id="cart__items">
      <div className="qty_box">
      <div className="inner_box">
      <img className="cocacola_img" src={cocacola} alt=""/>
        <h4>کوکا کولا 1.5لیٹر</h4>
        <b>Rs. 90 <h6>Rs. 100</h6></b>
        <div className="number qty__number">
          <span className="minus">-</span>
          <input type="text" className="middle_qty" value="1"/>
          <span className="plus">+</span>
        </div>
        <Link to="#" className="order_cancel">کینسل کریں</Link>
      </div>
  </div>

  <div className="qty_box">
      <div className="inner_box">
        <img className="cocacola_img" src={cocacola} alt=""/>
        <h4>کوکا کولا 1.5لیٹر</h4>
        <b>Rs. 90 <h6>Rs. 100</h6></b>
        <div className="number qty__number">
          <span className="minus">-</span>
          <input type="text" className="middle_qty" value="1"/>
          <span className="plus">+</span>
        </div>
        <Link to="#" className="order_cancel">کینسل کریں</Link>
      </div>
  </div>

  <div className="qty_box">
      <div className="inner_box">
        <img className="cocacola_img" src={cocacola} alt=""/>
        <h4>کوکا کولا 1.5لیٹر</h4>
        <b>Rs. 90 <h6>Rs. 100</h6></b>
        <div className="number qty__number">
          <span className="minus">-</span>
          <input type="text" className="middle_qty" value="1"/>
          <span className="plus">+</span>
        </div>
        <Link to="#" className="order_cancel">کینسل کریں</Link>
      </div>
  </div>
</div>
    

  </div>
</section>


<div className="procced_checkout">
  <Link to="/checkout">
    <div className="row">
      <div className="col-sm-6 checkout_info">
        <h4>Proceed to Checkout</h4>
      </div>
      <div className="col-sm-6 checkout_info checkout_value">
        <h6>Rs. 10226</h6>
      </div>
    </div>
  </Link>
</div>



</body>

        </React.Fragment>
    )
}
}
export default Cart;
