import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import dots  from '../assets/images/slots-dots.png';
import $ from 'jquery';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config';
class Checkout extends Component {
componentDidMount(){
$(".order_cancel,.items_box a").click(function(){
	$(".cancel_box,.total_box").fadeIn();
});
$(".close_btn_popup i,.cancel_box").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});

$(".delivery_box li a").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});


$(".delivery_box li a").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeIn();
});

$(".close_btn_icon i,.overlay_poppu").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeOut();
});

// sidebar

$(".dots_icon img").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

$(".slots_items_box .choose_slots").click(function(){
  $(".set_estimated,.set_overlay").fadeIn();
});
$(".set_overlay,.time_picker > i,.set_estimated .gendar input").click(function(){
  $(".set_estimated,.set_overlay").fadeOut();
});


// qtybox

$(document).ready(function() {
	$('.minus').click(function () {
		var $input = $(this).parent().find('input');
		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.change();
		return false;
	});
	$('.plus').click(function () {
		var $input = $(this).parent().find('input');
		$input.val(parseInt($input.val()) + 1);
		$input.change();
		return false;
	});
});
}

  render(){

    return (
<React.Fragment>
          

<body className="yellow_bg border_none ">

<Header title="Checkout" />
<section className="login_box__view listing_view purple_bg" id="height_box" style={{height:'100vh'}}>
  <div className="checkout_textarea order_details searchbar myroute_search" id="wallet_page">
    <div className="info_order">
      <h4>Your Delivery Address</h4>
      <table className="table">
        <tbody>
        <tr>
          <th>357, Block N Shah Jamal Gulberg III, Lahore, Punjab 53700, Pakistan</th>
          <td className="order_total fee_green"></td>
        </tr>
        <tr>
          <td colspan="2"><input type="text" placeholder="Instructions for delivery team." name="" className="" value=""/></td>
        </tr>
        
    </tbody>
  </table>
    </div>

    <div className="info_order slots_items_box">
      <h4>Choose delivery slot</h4>
      <table className="table">
        <tbody>
        <tr>
          <th>TOMORROW</th>
          <td className="order_total fee_green choose_slots">6 MAR <img src={dots} alt=""/></td>
        </tr>
        <tr>
          <th>SUN </th>
          <td className="order_total fee_green choose_slots">7 MAR <img src={dots} alt=""/></td>
        </tr>
        <tr>
          <th>MON</th>
          <td className="order_total fee_green choose_slots">8 MAR <img src={dots} alt=""/></td>
        </tr>
        <tr>
          <th>TUE</th>
          <td className="order_total fee_green choose_slots">9 MAR <img src={dots} alt=""/></td>
        </tr>
        
    </tbody>
  </table>
    </div>

    <div className="info_order">
      <h4>Current Payment Method</h4>
      <table className="table">
        <tbody>
        <tr>
          <th>
            <div className="gendar">
                <input type="radio" id="test20" name="radio-group" value=""/>
                <label for="test20">(COD) <svg id="cod"  viewBox="0 0 24 24"><path d="M11 4h1v2.01c3.29.141 4 1.685 4 2.99h-1c0-1.327-1.178-2-3.5-2c-.82 0-3.5.163-3.5 2.249c0 .872 0 1.86 3.621 2.766l1.606.485C15.76 13.435 16 14.572 16 15.751c0 1.881-1.518 3.093-4 3.235V21h-1v-2.01c-3.29-.141-4-1.685-4-2.99h1c0 1.327 1.178 2 3.5 2c.82 0 3.5-.163 3.5-2.249c0-.872 0-1.86-3.621-2.766L9.773 12.5C7.24 11.565 7 10.428 7 9.249c0-1.881 1.518-3.093 4-3.235V4z" fill="#626262"></path></svg></label>
            </div>
          </th>
          <td className="check_mark"><svg viewBox="0 0 24 24"><path d="M18.9 8.1L9 18l-4.95-4.95l.707-.707L9 16.586l9.192-9.193l.707.708z" fill="#51aa1a"></path></svg></td>
        </tr>
        
        
    </tbody>
  </table>
    </div>

  </div>
</section>


<div className="procced_checkout">
  <Link to="/myorder">
    <div className="row">
      <div className="col-sm-6 checkout_info">
        <h4>Place Order</h4>
      </div>
      <div className="col-sm-6 checkout_info checkout_value">
        <h6>Rs. 10226</h6>
      </div>
    </div>
  </Link>
</div>


<div className="set_overlay"></div>
<div className="set_estimated">
  <div className="time_picker">
    <i className="lnr lnr-cross"></i>
    <b>Choose delivery slot</b>
    <div className="gendar">
        <input type="radio" id="test1" name="radio-group" checked="" value=""/>
        <label for="test1">7:00 AM - 12:30 AM</label>
        <input type="radio" id="test2" name="radio-group" value=""/>
        <label for="test2">8:00 AM - 12:30 AM</label>
        <input type="radio" id="test3" name="radio-group" value=""/>
        <label for="test3">9:00 AM - 12:30 AM</label>
        <input type="radio" id="test4" name="radio-group" value=""/>
        <label for="test4">7:00 AM - 12:30 AM</label>
    </div>
  </div>
</div>

</body>


        </React.Fragment>
    )
}
}
export default Checkout;
