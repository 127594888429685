import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import brand_1  from '../assets/images/Coca-Cola-Logo.wine.png';
import brand_2  from '../assets/images/Mirinda-Logo-2012.png';
import brand_3  from '../assets/images/32192-5-pepsi-logo-transparent-image-thumb.png';
import brand_4  from '../assets/images/sprite_PNG98769.png';
import brand_5  from '../assets/images/583bf92e1b271.png';
import brand_6  from '../assets/images/133262.png';
import brand_7  from '../assets/images/5ae22d6f33b73fa43b1a893b (1).png';
import brand_8  from '../assets/images/Layer-19.png';
import brand_9  from '../assets/images/brand.png';
import brand_10  from '../assets/images/brand11.png';
import brand_11  from '../assets/images/brand12.png';
import brand_12  from '../assets/images/brand13.png';
import brand_13  from '../assets/images/brand14.png';
import brand_14  from '../assets/images/brand15.png';
import brand_15  from '../assets/images/brand15.png';
import brand_16  from '../assets/images/brand16.png';
import items_list_1  from '../assets/images/cocacola.jpg';
import cocacola  from '../assets/images/cocacola.jpg';
import p1  from '../assets/images/p1.jpg';
import p2  from '../assets/images/p2.png';
import p3  from '../assets/images/p3.png';
import p4  from '../assets/images/p4.png';
import p5  from '../assets/images/p5.jpg';
import p6  from '../assets/images/p6.png';
import $ from 'jquery';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config';
class Home extends Component {
componentDidMount(){
$(".order_cancel,.items_box a").click(function(){
	$(".cancel_box,.total_box").fadeIn();
});
$(".close_btn_popup i,.cancel_box").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});

$(".delivery_box li a").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});


$(".delivery_box li a").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeIn();
});

$(".close_btn_icon i,.overlay_poppu").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeOut();
});

// sidebar

$(".dots_icon img").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

$(".slots_items_box .choose_slots").click(function(){
  $(".set_estimated,.set_overlay").fadeIn();
});
$(".set_overlay,.time_picker > i,.set_estimated .gendar input").click(function(){
  $(".set_estimated,.set_overlay").fadeOut();
});


// qtybox

$(document).ready(function() {
	$('.minus').click(function () {
		var $input = $(this).parent().find('input');
		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.change();
		return false;
	});
	$('.plus').click(function () {
		var $input = $(this).parent().find('input');
		$input.val(parseInt($input.val()) + 1);
		$input.change();
		return false;
	});
});
}

  render(){

    return (
<React.Fragment>
          

<body className="yellow_bg border_none">




<Header title="ORDER EXPRESS" tagline="دوکان" />


<div className="yellow_bar" id="dark_bar"></div>
<div className="brand_bg" id="special_offer">
  <h4>SPECIAL OFFER</h4>
</div>

<div id="myCarousel" className="carousel slide" data-ride="carousel">
   
    <div className="carousel-inner">
      <div className="item active">
        <div className="slider_box">
          <div className="special_offer_box">
            <ul>
              <li>
                <Link to="/orderaddress" className="order_adress">
                <div className="items_box">
                  <div className="tag_box">
                    <h6>1.5 LTR</h6>
                  </div>
                  <img className="cocacola_img" src={cocacola} alt=""/>
                  <div className="row">
                    <div className="col-sm-6 line_through">
                      <h5>Rs. 100</h5> 
                    </div>
                    <div className="col-sm-6 brand__name">
                      <span>کوکا کولا </span> <span className="price__box">Rs. 90</span>
                    </div>
                  </div>
                  <Link to="/orderaddress">ابھی خریدیں </Link>
                </div>
                </Link>
              </li>
              <li>
                <Link to="/orderaddress" className="order_adress">
                <div className="items_box">
                  <div className="tag_box">
                    <h6>1.5 LTR</h6>
                  </div>
                  <img className="cocacola_img" src={cocacola} alt=""/>
                  <div className="row">
                    <div className="col-sm-6 line_through">
                      <h5>Rs. 100</h5> 
                    </div>
                    <div className="col-sm-6 brand__name">
                      <span>کوکا کولا </span> <span className="price__box">Rs. 90</span>
                    </div>
                  </div>
                  <Link to="#">ابھی خریدیں </Link>
                </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="item">
        <div className="slider_box">
          <div className="special_offer_box">
            <ul>
              <li>
                <Link to="/orderaddress" className="order_adress">
                <div className="items_box">
                  <div className="tag_box">
                    <h6>1.5 LTR</h6>
                  </div>
                  <img className="cocacola_img" src={cocacola} alt=""/>
                  <div className="row">
                    <div className="col-sm-6 line_through">
                      <h5>Rs. 100</h5> 
                    </div>
                    <div className="col-sm-6 brand__name">
                      <span>کوکا کولا </span> <span className="price__box">Rs. 90</span>
                    </div>
                  </div>
                  <Link to="/orderaddress">ابھی خریدیں </Link>
                </div>
                </Link>
              </li>
              <li>
                <Link to="/orderaddress" className="order_adress">
                <div className="items_box">
                  <div className="tag_box">
                    <h6>1.5 LTR</h6>
                  </div>
                  <img className="cocacola_img" src={cocacola} alt=""/>
                  <div className="row">
                    <div className="col-sm-6 line_through">
                      <h5>Rs. 100</h5> 
                    </div>
                    <div className="col-sm-6 brand__name">
                      <span>کوکا کولا </span> <span className="price__box">Rs. 90</span>
                    </div>
                  </div>
                  <Link to="#">ابھی خریدیں </Link>
                </div>
                </Link>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    
      
    </div>

    <Link className="left carousel-control" to="#myCarousel" data-slide="prev">
      <span className="glyphicon glyphicon-chevron-left"></span>
      <span className="sr-only">Previous</span>
    </Link>
    <Link className="right carousel-control" to="#myCarousel" data-slide="next">
      <span className="glyphicon glyphicon-chevron-right"></span>
      <span className="sr-only">Next</span>
    </Link>
  </div>


<div className="yellow_bar" id="all_products"></div>
<div className="brand_bg">
  <h4>PRODUCTS</h4>
</div>

<div className="all_products_box">
  <ul>
    <li>
      <Link to="/brands">
        <h4>مشروبات</h4>
        <img className="cocacola_img" src={p1} alt=""/>
      </Link>
    </li>

    <li>
      <Link to="/brands">
        <h4>دودھ، کریم</h4>
        <img className="cocacola_img" src={p2} alt=""/>
      </Link>
    </li>

    <li>
      <Link to="/brands">
        <h4>آئل، گھی</h4>
        <img className="cocacola_img" src={p3} alt=""/>
      </Link>
    </li>

    <li>
      <Link to="/brands">
        <h4>جوس</h4>
        <img className="cocacola_img" src={p4} alt=""/>
      </Link>
    </li>

    <li>
      <Link to="/brands">
        <h4>دالیں </h4>
        <img className="cocacola_img" src={p5} alt=""/>
      </Link>
    </li>

    <li>
      <Link to="/brands">
        <h4>مصالحہ جات</h4>
        <img className="cocacola_img" src={p6} alt=""/>
      </Link>
    </li>


  </ul>
</div>


<div className="yellow_bar"></div> 
<div className="brand_bg">
  <h4>BRANDS</h4>
</div>
<div className="brands_logos home_brands">
  <ul>
    <li>
      <Link to="/brands">
      <img src={brand_1} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_2} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_3} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_4} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_5} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_6} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_7} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_8} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_9} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_10} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_11} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_12} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_13} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_14} alt=""/>
      </Link>
    </li>
    <li>
      <Link to="/brands">
        <img src={brand_15} alt=""/>
      </Link>
    </li>
  </ul>
</div>


<div className="cancel_box"></div>
<div className="total_box" id="special__offer">
  <div className="total_box_inner">
    <div className="close_btn_popup">
      <i className="lnr lnr-cross"></i>
      
      <div className="qty_box" id="special_offer_popup">
      <div className="inner_box">
        <img className="cocacola_img" src={items_list_1} alt=""/>
        <h4>کوکا کولا 1.5لیٹر</h4>
        <b>Rs. 90</b>
        <div className="number qty__number">
          <span className="minus">-</span>
          <input type="text" className="middle_qty" value="1"/>
          <span className="plus">+</span>
        </div>
    </div>
  </div>
      <div className="delivery_box">
        <h6>ڈلیوری! کل</h6>
        <p>اگر شام 6 بجے سے پہلے آرڈر کریں گے</p>
        <ul>
          <li><Link to="#">ٹوکری میں شامل کریں </Link></li>
        </ul>
      </div>
    </div>
  </div>
</div>
</body>

<Footer/>
        </React.Fragment>
    )
}
}
export default Home;
