import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import $ from 'jquery';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config';
class Profile extends Component {
componentDidMount(){
$(".order_cancel,.items_box a").click(function(){
	$(".cancel_box,.total_box").fadeIn();
});
$(".close_btn_popup i,.cancel_box").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});

$(".delivery_box li a").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});


$(".delivery_box li a").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeIn();
});

$(".close_btn_icon i,.overlay_poppu").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeOut();
});

// sidebar

$(".dots_icon img").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

$(".slots_items_box .choose_slots").click(function(){
  $(".set_estimated,.set_overlay").fadeIn();
});
$(".set_overlay,.time_picker > i,.set_estimated .gendar input").click(function(){
  $(".set_estimated,.set_overlay").fadeOut();
});


// qtybox

$(document).ready(function() {
	$('.minus').click(function () {
		var $input = $(this).parent().find('input');
		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.change();
		return false;
	});
	$('.plus').click(function () {
		var $input = $(this).parent().find('input');
		$input.val(parseInt($input.val()) + 1);
		$input.change();
		return false;
	});
});
}

  render(){

    return (
<React.Fragment>
          

<body className="yellow_bg border_none">

<Header title="Track Order"/>

<section className="login_box__view listing_view purple_bg" id="height_box" style={{height:'100vh'}}>
  <div className="main_wrap dashboard_box">
    <form action="" method="">
      <div className="track_order">
      <h4>Track Your Order</h4>
      <input type="text" name="" />
      <Link to="/orderdetails"> <button type="button">VIEW YOUR STATUS</button></Link>
    </div>
    </form>
    
  </div>
</section>
<div className="footer_menu">
  <ul>
    <li>
      <Link to="/home">

        <svg viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#690048"/></svg>

      </Link>
    </li>

    <li>
      <Link to="/profile">
        <svg viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM12 13c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4zm6 5H6v-.99c.2-.72 3.3-2.01 6-2.01s5.8 1.29 6 2v1z" fill="#690048"></path></svg>
        
      </Link>
    </li>

    <li>
      <Link to="login">
        <svg viewBox="0 0 24 24"><path d="M5 3h6a3 3 0 0 1 3 3v4h-1V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm3 9h11.25L16 8.75l.664-.75l4.5 4.5l-4.5 4.5l-.664-.75L19.25 13H8v-1z" fill="#690048"/>
        </svg>
      </Link>
    </li>
  </ul>
</div>
</body>


        </React.Fragment>
    )
}
}
export default Profile;
