import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import items_list_1  from '../assets/images/cocacola.jpg';
import vectorsmart  from '../assets/images/Vector-Smart-Object.png';
import logo  from '../assets/images/LOGO.png';
import qtyimg  from '../assets/images/qty_boxes_03.png';
import $ from 'jquery';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config';
class OrderAddress extends Component {
componentDidMount(){
$(".order_cancel,.items_box a").click(function(){
	$(".cancel_box,.total_box").fadeIn();
});
$(".close_btn_popup i,.cancel_box").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});

$(".delivery_box li a").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});


$(".delivery_box li a").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeIn();
});

$(".close_btn_icon i,.overlay_poppu").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeOut();
});

// sidebar

$(".dots_icon img").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

$(".slots_items_box .choose_slots").click(function(){
  $(".set_estimated,.set_overlay").fadeIn();
});
$(".set_overlay,.time_picker > i,.set_estimated .gendar input").click(function(){
  $(".set_estimated,.set_overlay").fadeOut();
});


// qtybox

$(document).ready(function() {
	$('.minus').click(function () {
		var $input = $(this).parent().find('input');
		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.change();
		return false;
	});
	$('.plus').click(function () {
		var $input = $(this).parent().find('input');
		$input.val(parseInt($input.val()) + 1);
		$input.change();
		return false;
	});
});
}

  render(){

    return (
<React.Fragment>
          

<body className="yellow_bg border_none">


<Header title="Oreder Experess" tagline="دوکان" />
<div  className="shop_info">
  <ul>
    <li><span>Waqas Store</span> <b>:دوکان کا نام</b></li>
    <li><span>0315-4068186</span> <b>: فون نمبر</b></li>
    <li><span>New Chanal City Lahore</span> <b>:ایڈریس</b></li>
  </ul>
</div>

<div className="qty_box">
  <div className="inner_box">
  <img className="cocacola_img" src={items_list_1} alt=""/>
    <h4>کوکا کولا 1.5لیٹر</h4>
    <b>Rs. 90</b>
    <div className="number qty__number">
      <span className="minus">-</span>
      <input type="text" className="middle_qty" value="1"/>
      <span className="plus">+</span>
    </div>
    <Link to="#" className="order_cancel">کینسل کریں</Link>
  </div>
</div>



<div className="overlay_poppu"></div>
<div className="popup_orderbox" style={{zIndex:'9'}}>
  <div className="overlayimg">
  <img  src={vectorsmart} alt=""/>
  </div>
  <div className="close_btn_icon">
    <i className="lnr lnr-cross"></i>
    <img  src={logo} alt=""/>
    <p>اوڈر ایکسپریس دوکان کے          <span> انتخاب کا شکریہ</span></p>
  </div>
</div>



<div className="cancel_box"></div>
<div className="total_box">
  <div className="total_box_inner">
    <div className="close_btn_popup">
      <i className="lnr lnr-cross"></i>
      <h4>آرڈر کنفرم کریں</h4>
      <div className="row">
        <div className="col-sm-4 order_price">
          <p>Rs. 100</p>
          <p>Rs. 18</p>
          <p>Rs. 90</p>
        </div>
        <div className="col-sm-4 qty__value">
          <p>1</p>
          <img  src={qtyimg} alt=""/>
        </div>
        <div className="col-sm-4 total_value_box">
          <p>:سب ٹوٹل</p>
          <p>:ڈسکاؤنٹ</p>
          <p>:کل رقم</p>
        </div>
      </div>
      <div className="delivery_box">
        <h6>ڈلیوری! کل</h6>
        <p>اگر شام 6 بجے سے پہلے آرڈر کریں گے</p>
        <ul>
          <li><Link to="#" className="cancel_btn">کینسل کریں </Link></li>
          <li><Link to="#">ابھی خریدیں </Link></li>
        </ul>
      </div>
    </div>
  </div>
</div>

</body>

<Footer/>
        </React.Fragment>
    )
}
}
export default OrderAddress;
