import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import logo  from '../assets/images/LOGO.png';
import logo_icon  from '../assets/images/Vector-Smart-Object.png';
import favicon_icon  from '../assets/images/favicon-icon.png';
import $ from 'jquery';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config';
class Home extends Component {

componentDidMount(){
   $(".loader").delay(3400).fadeOut("slow");
  $("#overlayer").delay(3400).fadeOut("slow");
// });
// $(window).load(function() {
    $(".loader").delay(3400).fadeOut("slow");
  $("#overlayer").delay(3400).fadeOut("slow");
}
  render(){

    return (

 <React.Fragment>



<body class="yellow_bg">

<span className="loader">
    <div className="logobox">
      <img src={logo} alt=""/>
      <img className="logo_icon" src={logo_icon} alt=""/>
      <p>Powered by Order Express</p>
    </div>
</span>


<div className="login_box">
  <div className="main_head">
  <img src={favicon_icon} alt=""/>
    <p>آپ کی اپنی دوکان </p>
  </div>
  <form action="" method="" className="middle_form">
    <div className="form_box">
      <i className="lnr lnr-user"></i>
      <input type="text" placeholder="Mobile number" required="required" name="" />
    </div>
    <div className="form_box">
      <i className="lnr lnr-lock"></i>
      <input type="text" placeholder="Password" required="required" name=""/>
    </div>
    <div className="forget_pas">
      <Link to="#"> کیا آپ پاسورڈ بھول گئے ہیں؟</Link>
    </div>
    <div className="login_btn">
      <Link to="/otpno" style={{padding:'0',border:'none',margin:'0',borderRadius:'0'}}>
      <button type="button">
        لاگ ان
      </button>
      </Link>

      <Link to="#">رجسٹر کریں </Link>
    </div>
  </form>
</div>

</body>


        </React.Fragment>
    )
}
}
export default Home;
