import React from 'react'
import { Link,  } from 'react-router-dom';
import favicon_icon  from '../assets/images/favicon-icon.png';

const Footer = () => {
    return (
        <div className="footer_bar">
          <p><img src={favicon_icon} alt=""/> <b>TOTAL: Rs.200</b></p>
      </div> 
    )
}

export default Footer
