import React from 'react';
import './App.css';
import {BrowserRouter  as Router, withRouter} from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Login from './container/Login.js';
import Brands from './container/Brands.js';
import Cart from './container/Cart.js';
import Home from './container/Home.js';
import ItemList from './container/ItemList.js';
import MyOrder from './container/MyOrder.js';
import Checkout from './container/Checkout.js';
import OrderAddress from './container/OrderAddress.js';
import OrderDetails from './container/OrderDetails.js';
import Otpno from './container/Otpno.js';
import Profile from './container/Profile.js';
import TrackOrder from './container/TrackOrder.js';
import Wallet from './container/Wallet.js';
// import NoPage from './container/NoPage';
function App() {
  return (
    <React.Fragment>
      	<Router>
      	<Switch>
          <Route exact path="/" component={Login}/>
          <Route path="/brands" component={Brands}/>
          <Route path="/cart" component={Cart}/>
          <Route path="/home" component={Home}/>
          <Route path="/itemlist" component={ItemList}/>
          <Route path="/myorder" component={MyOrder}/>
          <Route path="/checkout" component={Checkout}/>
          <Route path="/orderaddress" component={OrderAddress}/>
          <Route path="/orderdetails" component={OrderDetails}/>
          <Route path="/otpno" component={Otpno}/>
          <Route path="/profile" component={Profile}/>
          <Route path="/trackorder" component={TrackOrder}/>
          <Route path="/wallet" component={Wallet}/>
		</Switch>
		</Router>
    </React.Fragment>
  );
}

export default App;
