import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import favicon_icon  from '../assets/images/favicon-icon.png';
import $ from 'jquery';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config';
class Profile extends Component {
componentDidMount(){
$(".order_cancel,.items_box a").click(function(){
	$(".cancel_box,.total_box").fadeIn();
});
$(".close_btn_popup i,.cancel_box").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});

$(".delivery_box li a").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});


$(".delivery_box li a").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeIn();
});

$(".close_btn_icon i,.overlay_poppu").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeOut();
});

// sidebar

$(".dots_icon img").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

$(".slots_items_box .choose_slots").click(function(){
  $(".set_estimated,.set_overlay").fadeIn();
});
$(".set_overlay,.time_picker > i,.set_estimated .gendar input").click(function(){
  $(".set_estimated,.set_overlay").fadeOut();
});


// qtybox

$(document).ready(function() {
	$('.minus').click(function () {
		var $input = $(this).parent().find('input');
		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.change();
		return false;
	});
	$('.plus').click(function () {
		var $input = $(this).parent().find('input');
		$input.val(parseInt($input.val()) + 1);
		$input.change();
		return false;
	});
});
}

  render(){

    return (
<React.Fragment>
          

<body className="yellow_bg">

<Header title="My Profile"/>

<div className="login_box">
  <form action="" method="" className="middle_form profilebox">
    <div className="main_head">
    <img src={favicon_icon} alt=""/>
  </div>
    <div className="form_box">
          <i className="lnr lnr-user"></i>
          <input type="text" placeholder="Name" required="required" name="name" />
      </div>
      <div className="form_box">
          <i className="lnr lnr-home"></i>
          <input type="text" placeholder="Shop Name" required="required" name="shopname" />
      </div>
      <div className="form_box">
          <i className="lnr lnr-map-marker"></i>
          <input type="text" placeholder="Shop Address" required="required" name="shopaddress" />
      </div>
      <div className="form_box">
          <i className="lnr lnr-apartment"></i>
          <input type="text" placeholder="Area" required="required" name="area" />
      </div>
      <div className="form_box">
          <i className="lnr lnr-license"></i>
          <input type="text" placeholder="CNIC Optional" required="required" name="cnic" className="" />
      </div>
      
    
    <div className="login_btn">
      <button type="submit">Submit</button>
    </div>
  </form>
</div>

</body>


        </React.Fragment>
    )
}
}
export default Profile;
