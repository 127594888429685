import React, {Component} from 'react';
import { Link,  } from 'react-router-dom';
import axios from "axios";
import items_list_1  from '../assets/images/cocacola.jpg';
import $ from 'jquery';
import Header from '../component/Header';
import Footer from '../component/Footer';
import {api_url} from '../Config';
class ItemList extends Component {
componentDidMount(){
$(".order_cancel,.items_box a").click(function(){
	$(".cancel_box,.total_box").fadeIn();
});
$(".close_btn_popup i,.cancel_box").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});

$(".delivery_box li a").click(function(){
	$(".cancel_box,.total_box").fadeOut();
});


$(".delivery_box li a").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeIn();
});

$(".close_btn_icon i,.overlay_poppu").click(function(){
	$(".overlay_poppu,.popup_orderbox").fadeOut();
});

// sidebar

$(".dots_icon img").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

$(".slots_items_box .choose_slots").click(function(){
  $(".set_estimated,.set_overlay").fadeIn();
});
$(".set_overlay,.time_picker > i,.set_estimated .gendar input").click(function(){
  $(".set_estimated,.set_overlay").fadeOut();
});


// qtybox

$(document).ready(function() {
	$('.minus').click(function () {
		var $input = $(this).parent().find('input');
		var count = parseInt($input.val()) - 1;
		count = count < 1 ? 1 : count;
		$input.val(count);
		$input.change();
		return false;
	});
	$('.plus').click(function () {
		var $input = $(this).parent().find('input');
		$input.val(parseInt($input.val()) + 1);
		$input.change();
		return false;
	});
});
}

  render(){

    return (
<React.Fragment>
          

<body className="yellow_bg border_none">




<Header title="Oreder Experess" tagline="دوکان" />

<div className="yellow_bar" id="dark_bar"></div>
<div className="brand_bg" id="special_offer">
  <h4>Items List</h4>
</div>
<div id="myCarousel" className="carousel slide" data-ride="carousel">

    <div className="slider_box" id="items_listing">
          <div className="special_offer_box">
            <ul>
              
              <li>
              <Link to="/orderaddress" class="order_adress">
                <div className="items_box">
                  <div className="tag_box">
                    <h6>1.5 LTR</h6>
                  </div>
                  <img src={items_list_1} alt=""/>
                  <div className="row">
                    <div className="col-sm-6 line_through">
                      <h5>Rs. 100</h5> 
                    </div>
                    <div className="col-sm-6 brand__name">
                      <span>کوکا کولا </span> <span className="price__box">Rs. 90</span>
                    </div>
                  </div>
                  <Link to="#">ابھی خریدیں </Link>
                </div>
                </Link>
              </li>
              <li>
              <Link to="/orderaddress" class="order_adress">
                <div className="items_box">
                  <div className="tag_box">
                    <h6>1.5 LTR</h6>
                  </div>
                  <img src={items_list_1} alt=""/>
                  <div className="row">
                    <div className="col-sm-6 line_through">
                      <h5>Rs. 100</h5> 
                    </div>
                    <div className="col-sm-6 brand__name">
                      <span>کوکا کولا </span> <span className="price__box">Rs. 90</span>
                    </div>
                  </div>
                  <Link to="#">ابھی خریدیں </Link>
                </div>
                </Link>
              </li>
              <li>
              <Link to="/orderaddress" class="order_adress">
                <div className="items_box">
                  <div className="tag_box">
                    <h6>1.5 LTR</h6>
                  </div>
                  <img src={items_list_1} alt=""/>
                  <div className="row">
                    <div className="col-sm-6 line_through">
                      <h5>Rs. 100</h5> 
                    </div>
                    <div className="col-sm-6 brand__name">
                      <span>کوکا کولا </span> <span className="price__box">Rs. 90</span>
                    </div>
                  </div>
                  <Link to="#">ابھی خریدیں </Link>
                </div>
                </Link>
              </li>
              <li>
              <Link to="/orderaddress" class="order_adress">
                <div className="items_box">
                  <div className="tag_box">
                    <h6>1.5 LTR</h6>
                  </div>
                  <img src={items_list_1} alt=""/>
                  <div className="row">
                    <div className="col-sm-6 line_through">
                      <h5>Rs. 100</h5> 
                    </div>
                    <div className="col-sm-6 brand__name">
                      <span>کوکا کولا </span> <span className="price__box">Rs. 90</span>
                    </div>
                  </div>
                  <Link to="#">ابھی خریدیں </Link>
                </div>
                </Link>
              </li>
              

            </ul>
          </div>
        </div>

  </div>






<div className="cancel_box"></div>
<div className="total_box" id="special__offer">
  <div className="total_box_inner">
    <div className="close_btn_popup">
      <i className="lnr lnr-cross"></i>
      
      <div className="qty_box" id="special_offer_popup">
      <div className="inner_box">
        <img class="cocacola_img" src={items_list_1} alt=""/>
        <h4>کوکا کولا 1.5لیٹر</h4>
        <b>Rs. 90</b>
        <div className="number qty__number">
          <span className="minus">-</span>
          <input type="text" className="middle_qty" value="1"/>
          <span className="plus">+</span>
        </div>
    </div>
  </div>
      <div className="delivery_box">
        <h6>ڈلیوری! کل</h6>
        <p>اگر شام 6 بجے سے پہلے آرڈر کریں گے</p>
        <ul>
          <li><Link to="#">ٹوکری میں شامل کریں </Link></li>
        </ul>
      </div>
    </div>
  </div>
</div>
</body>

<Footer/>
        </React.Fragment>
    )
}
}
export default ItemList;
