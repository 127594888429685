import React,{useState, useEffect } from 'react'
import { Link,  } from 'react-router-dom';
import $ from 'jquery';
import logo  from '../assets/images/LOGO.png';
import dots  from '../assets/images/dots.png';
const Header = (props) => {
useEffect(() => {
   $(".dots_icon img").click(function(){
  $(".sidebar_box,.overlay_body").fadeIn();
});

$(".overlay_body,.close_btn").click(function(){
  $(".sidebar_box,.overlay_body").fadeOut();
});

  },[]);
    return (

    <React.Fragment>

<section className="sidebar_box">
  <div className="menu_head">
    <div className="close_btn">
      <i className="lnr lnr-cross"></i>
    </div>
    <img src={logo} alt=""/>
    <h4>Salam, Guest User!</h4>
  </div>
  <div className="navbar_items">
    <ul>
      <li>
      <Link to="/home">

        <svg viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
        Home
      </Link>
    </li>
      
      <li>
        <Link to="/profile">My Profile</Link>
        <svg className="jss314" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zM12 13c-2.67 0-8 1.34-8 4v2c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-2c0-2.66-5.33-4-8-4zm6 5H6v-.99c.2-.72 3.3-2.01 6-2.01s5.8 1.29 6 2v1z"></path></svg>
      </li>
      <li>
        <Link to="/myorder">My Orders</Link>
        <svg className="jss314" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><g fill="none"><path d="M0 0h24v24H0V0z"></path><path d="M0 0h24v24H0V0z" opacity=".87"></path></g><path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"></path><path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"></path></svg>
      </li>
      
      <li>
        <Link to="/wallet">Wallet <span>PKR 2299</span></Link>
        <svg className="jss314" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0z"></path><path d="M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path></svg>
      </li>
      <li>
        <Link to="/trackorder">Track Order</Link>
        <svg className="jss314" focusable="false" viewBox="0 0 24 24"><path d="M5.5 14a2.5 2.5 0 0 1 2.45 2H15V6H4a2 2 0 0 0-2 2v8h1.05a2.5 2.5 0 0 1 2.45-2zm0 5a2.5 2.5 0 0 1-2.45-2H1V8a3 3 0 0 1 3-3h11a1 1 0 0 1 1 1v2h3l3 3.981V17h-2.05a2.5 2.5 0 0 1-4.9 0h-7.1a2.5 2.5 0 0 1-2.45 2zm0-4a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm12-1a2.5 2.5 0 0 1 2.45 2H21v-3.684L20.762 12H16v2.5a2.49 2.49 0 0 1 1.5-.5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM16 9v2h4.009L18.5 9H16z" fill="#fff"></path></svg>
      </li>
      <li>
        <a href="tel:03211234567">Call Us</a>
        <svg className="jss314" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><g><path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z"></path><path d="M13 11h4c.55 0 1-.45 1-1s-.45-1-1-1h-1.59l4.31-4.31c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0L14 7.59V6c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1z"></path></g></svg>
      </li>
      <li>
        <Link to="/">Sign In</Link>
        <svg  viewBox="0 0 24 24"><path d="M15 3H9a3 3 0 0 0-3 3v4h1V6a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-4H6v4a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zM3 12h10.25L10 8.75l.664-.75l4.5 4.5l-4.5 4.5l-.664-.75L13.25 13H3v-1z" fill="#fff"/></svg>

      </li>
      
    </ul>
  </div>
</section>

<div className="overlay_body"></div>
<div className="shopname">
  <h4>{props.title} <span>{props.tagline}</span></h4>
  <div className="dots_icon">
  <img src={dots} alt=""/>
  </div>
  <div className="cart_box">
    <Link to="/cart"><svg className="jss314" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM17 18c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path></svg></Link>
    <div className="cart_items"><span>8</span></div>
  </div>
</div>

</React.Fragment>
    )
}

export default Header;
